import forEdit from "@/mixin/forEdit";
import clearListPageStateOnCreated from "@/mixin/clearListPageStateOnCreated";
// 
function IntegerValidator (rule, value, cb) {
    const fine = Number.isInteger(value) && value >= 0;
    if (fine) cb();
    else cb('请输入0或正整数');
}

export default {
    name: "SystemBasic",
    mixins: [forEdit],

    props: {},

    data() {
        return {

            // post body 请求体数据
            PB: {
                isLogin: 0, // 是否必须登录,0,1
                wxAuth: 1, // 是否要求微信授权,0,1
                isRegister: 1, // 是否开启注册,0,1
                isWxPay: 1, // 是否开启微信充值,0,1
                hotKeywords: "", // 热搜关键字
                minIntegral: 0, // 最小积分，积分保底额
                giveIntegral: 2500, // 拉新积分，老客户推荐新客户的积分
                goodsDays: 25, // 实体商品完成时间，交易周期
                serverDays: 7, // 服务商品完成时间，交易周期
                waitDays: 7, // 待支付订单默认取消时间
                orderEvaluateDays: 15, // 订单完成后可评论时间
                outOrderDays: 30,   // 支付后可申请退单的时间
                snsDay: "7,14,30", // 待支付订单短信提醒周期
                // sendOrderInfoToBusiness: false, // 订单邮件是否发送给服务商
                rechargeRatio: [1, 10], // 充值比例：人民币:积分
                tips: 100, // 优惠券兑现比列：优惠券兑现报销比例
                goodSn: "", // 日程管理显示的商品
                aymCouponNo: '',//商业移民劵
                crmCouponNo: '',//成交客户租房优惠卡
            },

            rules: {
                minIntegral: [
                    {
                        required: true,
                        message: "请输入积分保底额",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator }
                ],
                giveIntegral: [
                    {
                        required: true,
                        message: "请输入老客户推荐新客户的赠送积分",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator }
                ],
                goodsDays: [
                    {
                        required: true,
                        message: "请输入实体商品的默认完成时间",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator }
                ],
                serverDays: [
                    {
                        required: true,
                        message: "请输入服务商品的默认完成时间",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator }
                ],
                waitDays: [
                    {
                        required: true,
                        message: "请输入待支付订单默认取消时间",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator }
                ],
                orderEvaluateDays: [
                    {
                        required: true,
                        message: "请输入订单完成后可评论时间",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator }
                ],
                outOrderDays: [
                    {
                        required: true,
                        message: "支付后可申请退单的时间",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator },
                    { validator: function (rule, value, cb) {
                        const fine = value <= 100;
                        if (fine) cb();
                        else cb('不能大于三位数');
                    }}
                ],
                snsDay: {
                    validator (rule, value, cb) {
                        const fine = /^\d+(,\d+)*,?$/.test(value);
                        if (fine) cb();
                        else cb('至少输入一个天数，多个天数用英文逗号","分割');
                    }
                },
                rechargeRatio: [
                    {required: true, message: "请输入充值比例设置"},
                    {
                        validator (rule, value, cb) {
                            const fine = Number(value[0]) > 0 && Number(value[1]) > 0;
                            if (fine) cb();
                            else cb('请输入合理的充值比例');
                        }
                    }
                ],
                tips: [
                    {
                        required: true,
                        message: "请输入优惠券兑现报销比例",
                        trigger: "blur"
                    },
                    { validator: IntegerValidator }
                ],
            },

            auth: {
                save: false,  // 修改权限
            },

            posting: false, // 状态：保存中
        }
    },

    methods: {

        // 获取页面数据
        fetchData() {
            this.loading = true;

            this.$post("api/config/queryConfigInfo")
            .then(res => {
                if (res.code === 2000) {
                    const data = res.data.configInfo;
                    for (let key in this.PB) {
                        switch (key) {
                            case "min_integral":
                                this.PB[key] = data.minIntegral
                                break;

                            case "rechargeRatio":
                                this.PB[key] = data.rechargeRatio.split(":")
                                break;

                            default: 
                                if (data[key] != null) this.PB[key] = data[key]
                                break;
                        }
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击提交按钮
        handleSubmitButton () {
			this.$refs.form.validate()

            .then(() => {
                this.posting = true;

                return this.$axios({
                    url: "api/config/saveConfig",
                    method: "post",
                    data: {
                        ...this.PB,
                        rechargeRatio: this.PB.rechargeRatio.join(":")
                    }
                })
            })

            .then(res => {
                const fine = 2000 == res.code;
                this.$message({
                    message: fine ? "保存成功" : res.msg || "保存失败",
                    type: fine ? "success" : "warning"
                })

                return fine ||  Promise.reject(res.msg)
            })

            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason || "请填写完整数据",
                    type: "warning"
                });
                console.log(reason);
            })

            .then(() => {
                this.posting = false;
            })
        }

    },

    created () {
        clearListPageStateOnCreated.call(this)
        this.fetchData();
        this.auth.save = this.$hasAuthFor("api/config/saveConfig");
    }
};