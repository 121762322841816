<template>
    <div class="system-menu-edit">编辑菜单</div>
</template>

<script>
export default {
    name: 'SystemMenuEdit',
    props: {},
    data () {
        return {}
    },
    computed: {
    },
    methods: {},
    created () {},
    beforeMount () {},
    mounted () {},
    components: {},
    watch: {}
}
</script>

<style lang='scss' scoped>
    
</style>