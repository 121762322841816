import { Loading } from "element-ui";
import forEdit from "@/mixin/forEdit";
import clearListPageStateOnCreated from "@/mixin/clearListPageStateOnCreated";

export default {
    name: "ExternalAssociate",
    mixins: [forEdit],

    props: {},

    data() {
        return {

            // post body 请求体数据
            PB: {
                yiminList: [], // 一名系统的配置
            },

            yiminRules: {
                goodsSn: {required: true, message: "请输入签证商品编号"},
                goodsSn2: { required: true, message: "请输入签约商品编号" },


                outconfigid: { required: true, message: "请选择移民项目名称", trigger: "blur" },
            },
            yiminTypes: [],
            typeList: [],

            auth: {
                save: false,  // 修改权限
            },

            posting: false, // 状态：保存中
        }
    },

    methods: {
        changeXiangmuguilei(index) {
            console.log(this.PB.yiminList);
            this.PB.yiminList[index].outconfigid = ''
            this.$refs.form.clearValidate();


        },

        // 获取页面数据
        fetchData() {
            this.loading = true;

            this.$post("api/config/queryOutSysContractConfig")
            .then(res => {
                if (res.code === 2000) {

                    const {outConfigList, contractObj} = res.data;
                    // this.PB.yiminList = []
                    this.PB.yiminList.push(...outConfigList)
                    // this.yiminTypes = []; 
                    this.typeList = res.data.typeList
                    this.PB.yiminList.forEach(element => {
                        element.yiminTypes = res.data.contractObj.mapList
                        element.contractAll = res.data.contractAll
                    });
                    this.contractAll = res.data.contractAll

                    this.yiminTypes.push(...contractObj.mapList)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击添加
        handleYiminAdd () {
            const newLine = {
                goodsSn: "",
                outconfigid: "",
                type: this.typeList[0].value || '',
                contractAll: this.contractAll,
                yiminTypes: this.yiminTypes,

            };
            // typeList
            
            // yiminTypes

            this.PB.yiminList.push(newLine);
            console.log(this.typeList);

            console.log(this.PB.yiminList);
        },
        handleYiminRemove (index) {
            let loadingR;
            this.$confirm("确定删除吗", "提示")
            .then(() => {
                const item = this.PB.yiminList[index];
                if (item.id) {
                    loadingR = Loading.service();
                    return this.$axios({
                        url: "api/config/deleteOutConfig",
                        method: "post",
                        data: { id: item.id }
                    })
                }
                return { code: 2000 }
            })
            .then(res => {
                if (res.code === 2000) {
                    this.PB.yiminList.splice(index, 1);
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                }
            })
            .catch(reason => {
                if (reason !== "cancel") {
                    this.$message({
                        message: "删除失败",
                        type: "warning"
                    });
                    console.warn("删除失败", reason);
                }
            })
            .then(() => loadingR && loadingR.close())
        },

        // 点击提交按钮
        handleSubmitButton () {
			this.$refs.form.validate()

            .then(() => {
                this.posting = true;

                return this.$axios({
                    url: "api/config/saveOutConfig",
                    method: "post",
                    data: {
                        outConfigListStr: JSON.stringify(this.PB.yiminList.map(item => {
                            return {
                                id: item.id,
                                outconfigid: item.outconfigid,
                                goodsSn: item.goodsSn,
                                type: item.type
                            }
                        }))
                    }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    })
                    this.PB.yiminList.splice(0, this.PB.yiminList.length)
                    this.yiminTypes.splice(0, this.yiminTypes.length);
                    this.fetchData();

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "warning"
                });
                console.log(reason);
            })
            .then(() => this.posting = false)
        }

    },

    created () {
        clearListPageStateOnCreated.call(this)
        // this.fetchData();
        this.auth.detail = this.$hasAuthFor("api/config/queryOutSysContractConfig");
        this.auth.delete = this.$hasAuthFor("api/config/deleteOutConfig");
        this.auth.save = this.$hasAuthFor("api/config/saveOutConfig");
    }
};