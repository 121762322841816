
import forEdit from "@/mixin/forEdit";

// 在 tree (每一级外层是一个arr) 中查找 id 为某个值的对象有没有 下级(children) 属性
function hasChildInTree(tree, id) {
    let result = false;
    start: {
        for (let i = 0; i < tree.length; i++) {
            const hasChild = tree[i].children instanceof Array;
            if (tree[i].id == id) {
                result = hasChild;
                break start;
            } else if (hasChild) {
                result = hasChildInTree(tree[i].children, id);
                // console.log(result, tree[i].children, id);
                if (result) break start;
            }
        }
    }
    return result;
}

export default {
    name: 'SystemRoleEdit',
    mixins: [forEdit],

    data () {
        return {
            // post body 请求体数据
            PB: {
                id: undefined, // 编辑才有id
                rolesName: "", // 角色名称
                rolesCode: "", // 角色CODE
                remark: "", // 备注
            },

            rules: {
                rolesName: {
                    required: true,
                    message: "请填写角色名称"
                }
            },

            // 树
            tree: [],
            
            treeProps: {
                children: 'children',
                label: 'menuname'
            }
        }
    },

    methods: {

        // 获取页面数据
        async fetchData() {
            this.loading = true;
            const res = await this.$axios({
                url: "api/role/getRoleInfo",
                method: "post",
                data: { roleid: this.PB.id}
            });

            const success = 2000 == res.code;
            
            if (success) {
                // #region 接口返回的数据结构
                /**
                    "res.data.roleResource": [ // 角色自身的资源
                        {
                            "role_id": 1,
                            "resource_id": 1,
                            "id": 1
                        }
                    ],
                    "res.data.role": { // 角色自身信息
                        "id": 1,
                        "rolesCode": "XS",
                        "rolesName": "销售员工",
                        "state": 0,
                        "remark": "销售",
                        "updateTime": "2021-09-07T07:08:06.000+0000",
                        "updateUser": null,
                        "createTime": null,
                        "createUser": null
                    },
                    "res.data.allresources": [ // 所有资源信息
                        {
                            "id": 1,
                            "parentid": 0,
                            "menuname": "客户管理",
                            "path": "/",
                            "icon": "/sds/sdsds/sadas.peg",
                            "type": 0,
                            "status": 1,
                            "ord": 10,
                            "updatetime": "2021-09-06T06:01:28.000+0000",
                            "updateuser": null,
                            "remark": "adadasdsa",
                            "webpath": null
                        }
                    ]
                 */
                // #endregion

                // 更新 基础信息，没有id 也需要获取树信息
                if (this.PB.id) for (let key in this.PB) {
                    this.PB[key] = res.data.role[key] || this.PB[key]
                }
                
                // 更新树节点
                if (!(this.tree && this.tree.length)) {
                    const qArr = [];
                    res.data.allresources.forEach(resource => {
                        const index = Number(resource.type - 1);
                        if (qArr[index] === undefined || !(qArr[index] instanceof Array)) {
                            qArr[index] = [];
                        }
                        qArr[index].push(resource);
                    });

                    const __len = qArr.length;
                    for (let i = __len - 1; i > 0; i--) {
                        const subArr = qArr[i];
                        const supArr = qArr[i - 1];
                        subArr.forEach(subItem => {
                            const id = subItem.parentid = Number(subItem.parentid);
                            for (let j = 0; j < supArr.length; j++) {
                                const supItem = supArr[j];
                                if (Number(supItem.id) === id) {
                                    if (supItem.children === undefined || !(supItem.children instanceof Array)) {
                                        supItem.children = []
                                    }
                                    supItem.children.push(subItem);
                                    break;
                                }
                            }
                        });
                    }
                    this.tree = qArr[0];
                }

                // 获取的角色具有的权限列表（选中的树节点）
                if (this.PB.id && res.data.roleResource instanceof Array && res.data.roleResource.length) {

                    const checkedKeys = res.data.roleResource
                        .filter(item => {
                            const flag = hasChildInTree(this.tree, item.resource_id);
                            return !flag;
                        })
                        .map(item => Number(item.resource_id));

                    this.$refs.tree.setCheckedKeys(checkedKeys);
                }

            }

            this.loading = false
        },

        // 点击提交按钮
        handleSubmitButton () {
            this.$refs.form.validate()

            .then(() => {
                this.posting  = true;
    
                return this.$axios({
                    url: "api/role/saveRole",
                    method: "post",
                    data: {
                        ...this.PB,
                        resourceids: this.$refs.tree.getHalfCheckedKeys().concat(
                            this.$refs.tree.getCheckedKeys()).join(",")
                    }
                })

            })

            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : (res.msg || "保存失败"),
                    type: success ? "success" : "warning"
                })

                if (success) {
                    this.$router.back();

                } else {
                    return Promise.reject(res.msg)
                }
            })

            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason || "请填写完整数据",
                    type: "warning"
                });
                console.log(reason);
            })

            .then(() => {
                this.posting = false;
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    created () {

        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 树结构 的信息
        const id = Number(this.$route.params.id);
        if (!id) {
            this.fetchData();
        }
    }
}