import forList from "@/mixin/forList";

export default {
    name: "SystemRoles",
    mixins: [ forList ],

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                search_rolename: "", // 角色名称
            }
        };
    },

    methods: {

		// 获取列表数据
		fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$oxios({
				url: "api/role/getRoleList",
				method: "post",
				data: {...this.extractParams()}
			})
			.then(res => {
				if (res.code === 2000 && res.data.data) {
					this.total = res.data.count;
					const list = res.data.data.map(item => {
						return {
							...item,
						}
					});
					this.tableData.splice(0, this.tableData.length, ...list)

				} else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push("/system/roles/add");
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(index) {
			const data = this.tableData[index];
			this.$router.push("/system/roles/edit/" + (data.id || 5));
		},

		// 点击表格行操作按钮: 删除
		handleDelete(index) {
			const data = this.tableData[index];
			this.$confirm("要删除这个角色吗")

                .then(() => {
					this.loading = true;

					return this.$axios({
						url: "api/role/deleteRole",
						method: "post",
						data: { roleid : data.id }
					})
				})

				.then(res => {
					const success = 2000 == res.code;

					this.$message({
						message: success ? "删除成功" : res.msg || "删除失败",
						type: success ? "success" : "warning"
					})

					this.loading = false;
					if (success) this.fetchData();
				})

                .catch(reason => {
                    console.warn(reason);
					this.loading = false;
                })
		},
    },

	created () {
		this.auth.save = this.$hasAuthFor("api/role/saveRole");
		this.auth.detail = this.$hasAuthFor("api/role/getRoleInfo");
		this.auth.delete = this.$hasAuthFor("api/role/deleteRole");
	}
};