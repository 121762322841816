import forList from "@/mixin/forList";
import forEdit from "@/mixin/forEdit";
import SystemMenuEdit from "../../../components/SystemMenuEdit.vue";

const typeOpts = [
	{
		label: "模块",
		value: 1
	},
	{
		label: "功能",
		value: 2
	},
	{
		label: "子功能",
		value: 3
	}
];

// 函数创建一个空的菜单数据
const initialPB = () => ({
	type: 1,
	parentid: "",
	menuname: "",
	path: "",
	icon: [],
	ord: 0,
	remark: ""
});

export default {
	name: "SystemMenu",
	mixins: [forList, forEdit],

	components: {
		"menu-edit": SystemMenuEdit
	},

	props: {},

	data() {
		return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
			SP_: {
				menuname: "",         // 菜单名
				path: "",             // 地址
				type: "",             // 类型，见类型配置
			},

			typeOpts,                 // 类型的选项
			listIsType1: [],          // type1 模块列表
			listIsType2: [],          // type2 功能列表
			listIsType3: [],          // type3 子功能列表

			PB: null, // POST BODY: 当前对话框编辑的数据
			rules: {
				parentid: {
					required: false,
					trigger: "blur",
					message: "请选择所属"
				},
				menuname: {
					required: true,
					trigger: "blur",
					message: "请填写菜单名"
				},
				// path: {
				// 	required: true,
				// 	trigger: "blur",
				// 	message: "请填写url地址"
				// },
			},
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            }

		};
	},

	computed: {

		// 对话框状态由 PB 是否有值产生
		showAddPupop () {
			return !!this.PB
		},

		// 编辑、新增窗口，验证规则
		// rules () {
		// 	return 
		// },

		// 编辑、新增窗口，当前选择的类别索引
		currentTypeIndex () {
			return this.PB && this.PB.type && Number(this.PB.type) - 1
		},

		// 编辑、新增窗口，父菜单列表：与当前选择的类型相关
		curentParentList () {
			return this[`listIsType${this.currentTypeIndex}`]
		}
	},

	methods: {

		// 表格序号组装
		indexMethod(index) {
			return String(index + 1).padStart(3, "0");
		},

		// 获取列表数据。传入 type[1:模快, 2:功能, 3:子功能] 表示获取某个 type 的列表，用于编
		// 辑/新增时，获取父列表数据。
		async fetchData(type) {
			if (this.loading) return;
			let data;
			if (!type) {
				this.loading = true;
				data = {...this.extractParams()};
			} else {
				data = {
					type,
					pageNum: 1,
					pageSize: 200
				}
			}
			// type || (this.loading = true);


			// 如果传入了 type，用传入的 type, 并获取到 data 中对应的列表数组
			// 以便于获取到后，更新其中的数据
			if (type && [1,2,3].includes(type)) {
				data.type = type;
				var listForType = this[`listIsType${type}`];
				// listForType.splice(0, listForType.length);
			}
			
			// if (listForType && listForType.length) return console.log("listForType has data.");

			this.$oxios({
				url: "api/resource/queryResourceAll",
				method: "post",
				data
			})
			.then(res => {
				if (res.code === 2000 && res.data.resourcelist) {
					// 获取主列表
					if (listForType == undefined) {
						this.total = res.data.count;
						const list = res.data.resourcelist.map(item => {
							return {
								...item,
								menuname: item.menu_name,
								parentid: item.parent_id || "",
								icon: item.icon ? item.icon.split(",").map(item => ({
									name: item.slice(item.lastIndexOf("/")+1),
									path: item,
									url: this.$baseURL + item
								})) : []
							}
						});
						this.tableData.splice(0, this.tableData.length, ...list)
	
					// 获取选项参数
					} else {
						listForType.splice(0, listForType.length, ...res.data.resourcelist)
					}

				} else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

		initialPB, // 创建并返回一个新的空的菜单数据的方法

		// 点击 "新增菜单"
		handleAddBtn() {
			this.PB = this.initialPB();
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(index) {
			const data = this.tableData[index];
			this.PB = {...data};
			setTimeout(() => {
				this.PB.parentid = data.parentid
			}, 50);
		},

		// 关闭对话框
		handleDialogClose() {
			this.PB = null
		},

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传一张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
        //   console.log(file);
          // 上传图标之前，判断图标格式
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 < 200;
    
          if (!isImage) {
            this.$message.error('上传的图标只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传的图标大小不能超过 200KB!');
          }
          if (isImage && isLt2M) {
            // this.userInterface.uploadIconProcess = true;
			this.PB.icon.push(file)
            return true;
          }
          return false;
        },
        
        handleProgress(e, file) {
			this.posting = true;
            const index = this.PB.icon.indexOf(file);
            this.$set(this.PB.icon, index, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file) {
			this.posting = false;
            const index = this.PB.icon.indexOf(file);
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.icon, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("img")
            } else {
                this.PB.icon.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (index) {
            this.PB.icon.splice(index, 1);
        },

		// 保存对话框
		handSubmitButton() {
			this.$refs.form.validate()

				.then(() => {
					this.posting = true;
					return this.$axios({
						url: "api/resource/saveResource",
						method: "post",
						data: {
							...this.PB,
							webpath: this.PB.web_path,
							parentid: this.PB.parentid || 0,
							icon: this.PB.icon?.[0]?.path
						}
					})
				})

				.then(res => {
					const fine = 2000 == res.code;
					this.$message({
						message: fine ? "保存成功" : res.msg || "保存失败",
						type: fine ? "success" : "warning"
					})

					if (fine) {
						this.PB = null;
						this.tableData.splice(0, this.tableData.length);
						this.fetchData();
					}

					return fine || Promise.reject(res.msg)
				})

				.catch(reason => {
					// this.$refs.form.validate 验证失败的拒绝结果是值 false
					// 对于验证失败，不用 message 提示，因为表单控件上已有提示
					reason && this.$message({
						message: reason || "请填写完整数据",
						type: "warning"
					});
					console.log(reason);
				})

				.then(() => {
					this.posting = false;
				})
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(index) {
			this.$confirm("确定要删除吗")
			
			.then(() => {
				const data = this.tableData[index];
				this.posting = true
				return this.$axios({
					url: "api/resource/deleteResource",
					method: "post",
					data: {
						resourceid: Number(data.id)
					}
				})
			})

			.then(res => {
				const success = 2000 == res.code;
				this.$message({
					message: success ? "删除成功" : res.msg || "删除失败",
					type: success ? "success" : "warning"
				})

				if (success) {
					this.tableData.splice(0, this.tableData.length);
					this.fetchData();
				}
				return success || Promise.reject(res)
			})
			
			.catch(reason => {
				console.warn(reason)
			})
			
			.then(() => {
				this.posting = false
			})
		},

	},

	watch: {
		currentTypeIndex: {
			immediate: true,
			handler (index) {
				this.parentid = "";
				index && this.fetchData(index)
			}
		},

		"PB.type": function(val) {
			if (val > 1) {
				this.rules.parentid.required = true
			} else {
				
				this.rules.parentid.required = false
			}
		}
	},

	created () {
		this.auth.save = this.$hasAuthFor("api/resource/saveResource");
		this.auth.delete = this.$hasAuthFor("api/resource/deleteResource");
	}
};