import forList from "@/mixin/forList";

export default {
    name: "SystemLogs",
    mixins: [forList],

    data() {
        return {

            // search params 列表查询参数
            SP_: {
                searchType: 1,       // 搜索类型，默认操作人
                searchValue: "",     // 搜索类容
                fromOper: "",        // 操作端 1pc 2小程序
                searchStartTime: "", // 开始时间
                searchEndTime: "",   // 结束时间
            },
            // dateRange: "", // 选中的时间范围（两个 Date对象 元素的数组）

            //#region 选项配置
            searchTypeOpts: [
                {
                    label: "操作人",
                    value: 1
                },
                {
                    label: "操作内容",
                    value: 2
                }
            ],
            fromOperOpts: [
                {
                    label: "小程序",
                    value: 2
                },
                {
                    label: "后台",
                    value: 1
                }
            ],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            //#endregion

            detailData: null,
            detailTitle: "输入参数"

        };
    },

    methods: {
        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/operLogs/getlist",
                method: "post",
                data: {
                    ...{ // 参数不足会报错，，
                        searchType: 1,       // 搜索类型，默认操作人
                        searchValue: "",     // 搜索类容
                        fromOper: "",        // 操作端 1pc 2小程序
                        searchStartTime: "", // 开始时间
                        searchEndTime: "",   // 结束时间
                    },
                    ...this.SP
                }
            })
            .then(res => {
                if (res.code === 2000 && res.data.operList) {
                    this.total = res.data.count;
                    const list = res.data.operList.map(item => {
                        if (item.in_param?.length > 20) {
                            item.short_in_param = item.in_param.slice(0,30) + "..."
                        }
                        if (item.out_param?.length > 20) {
                            item.short_out_param = item.out_param.slice(0,30) + "..."
                        }
                        return item;
                    });
                    this.tableData.splice(0, this.tableData?.length, ...list)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 清空 SP_: 将高级搜索的字段恢复为默认值（见 data.SP_）
        clearSP_ () {
            for (let key in this.SP_) {
                const oVal = this.SP_[key];
                switch (key) {
                    case "pageSize":
                    case "pageNum":
                        break;

                    case "searchType":
                    case "fuzzyQueryType":
                        this.SP_[key] = 1;
                        break;

                    default: 
                        if (oVal.constructor === Array) this.SP_[key] = [];
                        else if (oVal.constructor === Object) this.SP_[key] = {};
                        else this.SP_[key] = "";
                        break;
                }
            }
        },
        // 查看详细参数
        handleInParam(index) {
            this.detailTitle = "输入参数"
            const item = this.tableData[index];
            this.detailData = item.in_param
        },
        handleOutParam(index) {
            this.detailTitle = "输出参数"
            const item = this.tableData[index];
            this.detailData = item.out_param
        },
        handleDetailClose () {
            this.detailData = null;
        }
    }
};